import { useAPI } from "../api/api";
import React, { useState, createContext, useEffect } from "react";
import { valueRate } from "common/Type/rateType";

const defaultValue: valueRate = {
  tier2Rate: null,
  revalidate: () => {},
  updateRate: () => {},
};

export const RateContext = createContext<valueRate>(defaultValue);

const RateProvider = React.memo((props) => {
  const [tier2Rate, setTier2Rate] = useState<any>(null);
  const api = useAPI();

  const updateRate = async (data: any) => {
    setTier2Rate(data);
  };

  const revalidate = async () => {
    getRate();
  };

  const value: valueRate = {
    tier2Rate,
    revalidate,
    updateRate,
  };

  const getRate = async () => {
    try {
      const res = await api.fetcher("get", "/default-rate");
      setTier2Rate(res);
    } catch (error) {
      setTier2Rate(null);
      localStorage.removeItem("tier2-rate");
    }
  };

  useEffect(() => {
    getRate();
  }, []);

  return <RateContext.Provider value={value as valueRate} {...props} />;
});

export default RateProvider;
