import { Stack } from "@mui/material";
import { RoomType } from "lib/types";
import React from "react";
import UserInfo from "./UserInfo";
interface Props {
  handleClick: (e: RoomType) => void;
  conversationSelected: number;
  dataRooms: RoomType[];
}

export interface dataUserInfo {
  id: number;
  name: string;
}

const RoomList: React.FC<Props> = ({
  handleClick,
  conversationSelected,
  dataRooms,
}) => {
  return (
    <Stack>
      {dataRooms?.map((item, index) => (
        <div
          key={index}
          onClick={() => handleClick(item)}
          style={{
            cursor: "pointer",
            padding: "8px 8px 8px 16px ",
            backgroundColor:
              conversationSelected === item.affiliator_id ? "#fff" : "unset",
          }}
        >
          <UserInfo
            name={item.affiliator.name}
            SizeText="body2"
            conversationSelected={conversationSelected}
            id={item.affiliator_id}
            url={item.affiliator.avatar_url}
            unReadMsg={item.has_unread_msg}
          />
        </div>
      ))}
    </Stack>
  );
};

export default RoomList;
