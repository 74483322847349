export const sidebarWidth = 300;

export const isCheck = {
  TRUE: 2,
  FALSE: 1,
};

export enum PERM {
  ADMIN = 1,
  PROJECT_OWNER = 2,
}

export const CHAIN_NAMES = {
  BINANCE_MAINNET: "BINANCE_MAINNET",
  BINANCE_TESTNET: "BINANCE_TESTNET",
  // POLYGON: "POLYGON",
  POLYGON_MUMBAI: "POLYGON_MUMBAI",
  ETHEREUM_MAINNET: "ETHEREUM_MAINNET",
  GOERLI: "GOERLI",
  SEPOLIA: "SEPOLIA",
} as const;

export const settingOption = [
  { label: "All", value: 1 },
  { label: "Belong to group", value: 2 },
  { label: "Not belong to group", value: 3 },
];

export const optionsHighlight = [
  { label: "Yes", value: 2 },
  { label: "No ", value: 1 },
  { label: "All", value: "" },
];

export const optionsView = [
  { label: "Yes", value: 1 },
  { label: "No ", value: 2 },
  { label: "All", value: "" },
];

// export const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: "asp-project-10bc9.firebaseapp.com",
//   projectId: "asp-project-10bc9",
//   storageBucket: "asp-project-10bc9.appspot.com",
//   messagingSenderId: "211765898947",
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
// };

export const optionType = [
  { label: "Text", value: 1 },
  { label: "Single selection", value: 2 },
  { label: "Multi selection", value: 3 },
];

export const template = {
  en:
    "〈sample〉\n\nPlease join us to promote BCG NFT sales, which will launch in April.\nClick here for Project WP↓\nhttps://bitbull-manual.gitbook.io/bitbull-project-affiliator/\n\n□PR Required Fields\n①Explain the project with reference to WP\n②Release date (4/4)\n③About the purchase bonus (can receive a limited edition NFT if you purchase during the month of April)\n\n□Example of past PR\nPlease create the following video with reference to PR.\nhttps://youtu.be/U8vIa4uvl0o\n\n□Notes\nIf you plan to PR on Youtube, please keep it limited public until approved.\n\n□Remark\nWe are open to negotiating PR prices.",
  ja:
    "〈サンプル〉\n\n4月にローンチするBCGのNFT販売のPRにご協力をお願いします。\nプロジェクトWPはこちら&darr;\nhttps://bitbull-manual.gitbook.io/bitbull-project-affiliator/\n\n□必須PR内容\n①WPを参考にプロジェクト概要\n②リリース日(4/4)\n③購入特典について(4月中の購入で限定NFTが付与されます)\n\n□過去PR例\n以下の動画をPRを参考に作成お願いします。\nhttps://youtu.be/U8vIa4uvl0o\n\n□注意点\nYoutubeでPRを予定の方、承認までは限定公開でお願いします。\n\n□備考\nPR価格の交渉も受け付けております。\n\n\n〈サンプル〉フォロワー2万人のBCGプロモーション依頼",
  ko:
    "〈샘플〉\n\n4월에 런칭하는 BCG의 NFT 판매 홍보에 협조 부탁드립니다.\n프로젝트WP는 이쪽입니다&darr;\nhttps://bitbull-manual.gitbook.io/bitbull-project-affiliator/\n\n□ 필수 PR 내용\n①WP를 참고하여 프로젝트 개요\n②발매일(4/4)\n③구매혜택에 대하여 (4월중 구매시 한정 NFT가 부여됩니다)\n\n□ 과거 PR 예\n아래 동영상을 PR을 참고하여 작성 부탁드립니다.\nhttps://youtu.be/U8vIa4uvl0o\n\n□주의점\n유튜브에서 홍보 예정이신 분, 승인 될 때 까지는 한정 공개 부탁드립니다.\n\n□비고 \nPR 홍보가격 협상을 받고 있습니다.\n\n\n<샘플> 팔로워 2만명 BCG 프로모션 의뢰",
};

export const checkCondition = {
  en:
    "※Affiliates will conduct PR based on the conditions listed here. Please be sure not to omit any information, as the description here will be used to determine whether or not you meet the conditions of the reward.",
  ja:
    "※アフィリエイターはこちらの条件に基づいてPRを実施します。こちらの記載が報酬条件を満たしているか否かになりますので、漏れの無いようにご記入ください。",
  ko:
    "※어필리에이터는 상기 조건에 따라 PR을 실시합니다.상기 기재가 보수 조건을 충족하는지 여부가 되므로 누락이 없도록 기입해 주십시오.",
};

export const snsRequestDetail = {
  en:
    "PR Overview: \n\n□ Essentials\n\n□ Desired Post Date\n\n□ PR material (image and video) Google Google drive link, etc\n\n□ Youtube\n\n□Twitter\nTask details (RT and likes)\nPeriod of holding",
  ja:
    "PR概要: \n\n□必須PR項目\n・\n・\n□希望投稿日\n\n□PR素材(画像や動画)※Googleドライブ等リンク\n\n□Youtube 動画の長さ\n\n□Twitter　　\nタスク詳細(RTやいいね)\n開催期間",
  ko:
    "홍보 개요: \n\n□ 필수 PR 항목\n\n□ 게시 날짜\n\n□ 홍보자료(이미지 및 동영상) Google Google 드라이브 링크 등\n\n□유튜브\n\n□트위터\n작업내용(RT 및 좋아요)\n개최기간",
};
export const priceOptions = [
  { label: "$0", value: 0 },
  { label: "$100", value: 100 },
  { label: "$200", value: 200 },
  { label: "$300", value: 300 },
  { label: "$400", value: 400 },
  { label: "$500", value: 500 },
  { label: "$600", value: 600 },
  { label: "$700", value: 700 },
  { label: "$800", value: 800 },
  { label: "$900", value: 900 },
  { label: "$1000", value: 1000 },
];

export const statusSNS = {
  END: 1,
  COMING: 2,
  GOING: 3,
};

export const statusWL = {
  END: 1,
  COMING: 2,
  GOING: 3,
};

export const claimSNSOptions = [
  { label: "Yes", value: 2 },
  { label: "No ", value: 1 },
  { label: "All", value: "" },
];
