import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { usePaginationQuery } from "lib/hooks";
import { Notification } from "lib/types";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { useAPI } from "api/api";
import { toast } from "react-toastify";
import { MinimizeScrollbar } from "components/MinimizeScrollbar";
import { useTranslation } from "react-i18next";

interface Props {
  handleClose: (values: any) => void;
  refetch: () => void;
}

const ListNotify: React.FC<Props> = ({ handleClose, refetch }) => {
  const [perPage, setPerPage] = useState<number>(10);
  const { paginationData, handleChangeParams } = usePaginationQuery<
    Notification
  >("notifications", {
    per_page: perPage,
  });

  const api = useAPI();

  const onClickLink = async (el: Notification) => {
    try {
      if (el?.type === 1) {
        await api.fetcher("post", "seen", { notification_id: el.id });
        // history.push(`project/edit/${el.project_id}`);
        window.location.href = `${process.env.REACT_APP_URL_PROJECT}/${el.project_id}`;
      }

      if (el?.type === 2) {
        await api.fetcher("post", "seen", { notification_id: el.id });
        window.location.href = `${process.env.REACT_APP_URL_PROPOSAL}/${el.project_id}`;
      }

      if (el?.type === 3) {
        await api.fetcher("post", "seen", { notification_id: el.id });
        window.location.href = `${process.env.REACT_APP_URL_PROPOSAL}/${el.project_id}`;
      }

      if (el?.type === 4) {
        await api.fetcher("post", "seen", { notification_id: el.id });
        window.location.href = `${process.env.REACT_APP_URL_PROPOSAL}/${el.project_id}`;
      }
      if (el?.type === 5) {
        await api.fetcher("post", "seen", { notification_id: el.id });
        window.location.href = `${process.env.REACT_APP_URL_PROJECT}/${el.project_id}`;
      }
      handleClose(null);
      refetch();
    } catch (error: any) {
      toast.error("Error");
    }
  };

  const handleScroll = useCallback(
    (event: any) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;
      const reachToBottom = scrollHeight - scrollTop === clientHeight;
      if (reachToBottom) {
        const params = {
          per_page: perPage + 10,
        };
        setPerPage((pre) => pre + 10);
        handleChangeParams(params);
      }
    },
    [handleChangeParams, perPage]
  );
  const {t} = useTranslation()

  return (
    <MinimizeScrollbar
      sx={{
        height: 400,
      }}
      contentProps={{
        display: "flex",
        flexDirection: "column",
      }}
      onScroll={
        perPage < Number(paginationData.pageCount) ? handleScroll : undefined
      }
    >
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
        }}
      >
        {paginationData.data &&
          paginationData.data?.map((el, ind) => (
            <>
              <ListItem
                alignItems="flex-start"
                onClick={() => onClickLink(el)}
                sx={{ cursor: "pointer" }}
                key={ind}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <ListItemText
                    sx={{ minWidth: "200px" }}
                    secondary={
                      <Stack direction="row" spacing={0.5}>
                        {(el?.action === 1 || el?.action === 2) &&
                          el?.type === 2 && (
                            <Typography sx={{ fontSize: 14 }}>
                              {t('notify.new_proposal')}
                            </Typography>
                          )}

                        {(el?.action === 1 || el?.action === 2) &&
                          el?.type === 3 && (
                            <Typography sx={{ fontSize: 14 }}>
                              {t('notify.submitted_work')}
                            </Typography>
                          )}

                        {el?.action === 2 && el?.type === 4 && (
                          <Typography sx={{ fontSize: 14 }}>
                            {t('notify.submitted_request')}
                          </Typography>
                        )}
                        {el?.action === 1 && el?.type === 5 && (
                          <Typography sx={{ fontSize: 14 }}>
                            {t('notify.new_faq')}
                          </Typography>
                        )}
                        {/* <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {el?.affiliator?.name}
                        </Typography>

                        {el?.action === 1 && (
                          <Typography sx={{ fontSize: 14 }}>create</Typography>
                        )}

                        {el?.action === 2 && (
                          <Typography sx={{ fontSize: 14 }}>update</Typography>
                        )}

                        {el?.type === 1 && (
                          <Typography sx={{ fontSize: 14 }}>project</Typography>
                        )}

                        {el?.type === 2 && (
                          <Typography sx={{ fontSize: 14 }}>
                            proposal
                          </Typography>
                        )}

                        {el?.type === 3 && (
                          <Typography sx={{ fontSize: 14 }}>work</Typography>
                        )}

                        {el?.type === 4 && (
                          <Typography sx={{ fontSize: 14 }}>request</Typography>
                        )} */}
                      </Stack>
                    }
                  />

                  {el?.status === 1 && (
                    <CircleIcon
                      sx={{
                        width: 8,
                        height: 8,
                        marginLeft: "8px",
                        fill: "#0052cc",
                      }}
                    />
                  )}
                </Stack>
              </ListItem>
              {ind < paginationData.data.length - 1 && <Divider />}
            </>
          ))}
      </List>
    </MinimizeScrollbar>
  );
};

export { ListNotify };
