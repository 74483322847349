import {
  Box,
  Stack,
  Typography,
  TypographyPropsVariantOverrides,
} from "@mui/material";
import React from "react";
import defaultAvatar from "assets/Avatar/avatar1.png";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from "@mui/types";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
interface Props {
  url?: string;
  name: string;
  SizeText?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
  id?: number;
  conversationSelected?: number;
  unReadMsg?: boolean;
}

const UserInfo: React.FC<Props> = ({
  url,
  name,
  SizeText,
  conversationSelected,
  id,
  unReadMsg,
}) => {
  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" alignItems="center">
          <img
            src={url || defaultAvatar}
            height="40px"
            width="40px"
            alt=""
            style={{
              marginRight: "10px",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          />
          <Typography
            variant={SizeText || "body1"}
            sx={{
              color: conversationSelected === id ? "#111" : "#fff",
              fontWeight: 700,
              overflow: "hidden",
              WebkitLineClamp: "1",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
            }}
          >
            {name}
          </Typography>
        </Stack>

        {unReadMsg && (
          <FiberManualRecordIcon
            style={{ fill: "red", width: 10, height: 10 }}
          />
        )}
      </Stack>
    </Box>
  );
};

export default UserInfo;
