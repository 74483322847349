/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
import { useMediaQuery, useTheme } from "@mui/material";
import { Breakpoints } from "@mui/system";
import { useScreen } from "./useScreen";

const defaultDirection = "down" as keyof Breakpoints;

const useMatchesSize = (direction: keyof Breakpoints = defaultDirection) => {
  const theme = useTheme();
  const { screen } = useScreen();
  const xs = useMediaQuery("(max-width:600px)");
  const sm = useMediaQuery(theme.breakpoints[direction]("sm"));
  const md = useMediaQuery(theme.breakpoints[direction]("md"));
  const onlyMd = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const lg = useMediaQuery(theme.breakpoints.up("md"));

  return { xs, sm, md, lg, theme, onlyMd, screen };
};

export { useMatchesSize };
