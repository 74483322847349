import { Stack } from "@mui/material";
import { AffiliatorData } from "common/Type/AffiliatorType";
import React from "react";
import UserInfo from "./UserInfo";
interface Props {
  handleClick: (e: AffiliatorData) => void;
  conversationSelected: number;
  dataAff: AffiliatorData[];
}

export interface dataUserInfo {
  id: number;
  name: string;
}

const RoomListAd: React.FC<Props> = ({
  handleClick,
  conversationSelected,
  dataAff,
}) => {
  return (
    <Stack>
      {dataAff?.map((item, index) => (
        <div
          key={index}
          onClick={() => handleClick(item)}
          style={{
            cursor: "pointer",
            padding: "8px 8px 8px 16px ",
            backgroundColor:
              conversationSelected === item.id ? "#fff" : "unset",
          }}
        >
          <UserInfo
            name={item.name}
            SizeText="body2"
            conversationSelected={conversationSelected}
            id={item.id}
            url={item.avatar_url}
            unReadMsg={item.has_unread_msg}
          />
        </div>
      ))}
    </Stack>
  );
};

export default RoomListAd;
