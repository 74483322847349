import React from "react";
import { Drawer, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory, Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import { sidebarWidth } from "common/constant";
import { Stack, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import TwitterIcon from "@mui/icons-material/Twitter";
import LanguageIcon from "@mui/icons-material/Language";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Link as MuiLink } from "@mui/material";
import { useTranslation } from "react-i18next";
import MouseIcon from "@mui/icons-material/Mouse";

const SideBarPO = React.memo((props: any) => {
  const classes = useStyle();
  const { sidebarList } = props;
  const location = useLocation();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const active = (path: string) => {
    if (path) {
      const pathname = location.pathname.split("/");
      const p = path.split("/");
      if (pathname[1] === p[1]) {
        return true;
      }
    }
    return false;
  };

  const handleUse = () => {
    if (i18n.language == "en" || i18n.language == "ko") {
      return "https://bitbull-manual.gitbook.io/bitbull-project-owner/project-owner-en/project-creation-social-media-wl";
    }
    if (i18n.language == "jp") {
      return "https://bitbull-manual.gitbook.io/bitbull-project-owner/project-owner-jp/purojekuto-snswl";
    }
    return "";
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer)}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        }),
      }}
    >
      <div className={classes.toolbar} style={{ marginTop: 15 }} />

      {sidebarList.map((c: any, index: number) => (
        <List key={index} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Link to={c?.path}>
            <ListItem
              button={true}
              className={active(c.path) ? classes.active : classes.unactive}
              onClick={() => {
                history.push(c.path);
              }}
            >
              {c?.icon && (
                <ListItemIcon className={classes.categoryIcon}>
                  {c.icon}
                </ListItemIcon>
              )}
              <ListItemText
                style={{
                  paddingLeft: 5,
                }}
              >
                <Typography fontWeight={700}>
                  {props.open ? c.label : ""}
                </Typography>
              </ListItemText>
            </ListItem>
          </Link>
        </List>
      ))}

      <MuiLink
        href={handleUse()}
        target="_blank"
        style={{ marginLeft: 16, marginTop: 16 }}
      >
        <Stack direction="row" spacing={2.5}>
          <MouseIcon sx={{ fill: "#1fb1e7" }} />

          <Typography color="#1fb1e7" fontWeight={700} fontSize={16}>
            {t("menu.how_to_use")}
          </Typography>
        </Stack>
      </MuiLink>

      <Stack
        spacing={1}
        color="white"
        sx={{ display: "flex", mt: "auto", mb: 3, ml: 2 }}
      >
        <Stack spacing={1} direction="row" alignItems="start">
          <EmailIcon
            sx={{
              fill: "white",
            }}
          />

          <MuiLink href="mailto:support@bitbull.online">
            <Typography color="white" fontSize={16}>
              support@bitbull.online
            </Typography>
          </MuiLink>
        </Stack>

        <Stack spacing={1} direction="row" alignItems="start">
          <TwitterIcon
            sx={{
              fill: "white",
            }}
          />

          <MuiLink
            href="https://twitter.com/BitBull_Global"
            style={{
              textDecoration: "none",
              color: "white",
              fontSize: 16,
            }}
            target="_blank"
          >
            https://twitter.com/BitBull_Global
          </MuiLink>
        </Stack>

        <Stack spacing={1} direction="row" alignItems="start">
          <TelegramIcon
            sx={{
              fill: "white",
            }}
          />

          <MuiLink
            href="https://t.me/bitbullsupport"
            style={{
              textDecoration: "none",
              color: "white",
              fontSize: 16,
            }}
            target="_blank"
          >
            https://t.me/bitbullsupport
          </MuiLink>
        </Stack>

        <Stack spacing={1} direction="row" alignItems="start">
          <LanguageIcon
            sx={{
              fill: "white",
            }}
          />

          <MuiLink
            href="https://bitbull.online"
            style={{
              textDecoration: "none",
              color: "white",
              fontSize: 16,
            }}
            target="_blank"
          >
            https://bitbull.online
          </MuiLink>
        </Stack>
      </Stack>

      <Stack alignItems="center" mt={2} mb={1}>
        <Typography color="white">©2023 All rights reserved</Typography>

        <MuiLink
          href="https://bitbull.online"
          style={{ color: "#1fb1e7" }}
          target="_blank"
        >
          https://bitbull.online
        </MuiLink>
      </Stack>
    </Drawer>
  );
});

const useStyle = makeStyles((theme) => ({
  drawer: {
    width: sidebarWidth,
    flexShrink: 0,
    fontSize: "1rem",
    "& svg": {
      color: theme.palette.primary,
    },
    "& a": {
      color: "#000",
      textDecoration: "none",
    },
  },
  drawerOpen: {
    width: sidebarWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#00132C",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 60,
    [theme.breakpoints.up("sm")]: {
      width: 60,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  active: {
    minHeight: 64,
    borderLeft: "#1fb1e7 solid 2px",
    color: "#1fb1e7",
  },
  unactive: {
    minHeight: 64,
    color: "#1fb1e7",
  },
  categoryIcon: {
    color: "#1fb1e7",
    minWidth: 40,
  },
}));
export default SideBarPO;
