import { DatePickerProps } from "@mui/lab";
import MuiDatePicker from "@mui/lab/DatePicker";
import { FormControlProps, IconButton, Stack, TextField } from "@mui/material";
import { isValid } from "date-fns";
import { formatISODate, safeParseISO } from "lib/utils";
import { useController, UseControllerProps } from "react-hook-form";
import { AddControlProps, InputControl } from "..";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

type ControlProps = AddControlProps & {
  controlProps?: FormControlProps;
  fullWidth?: boolean;
};

type DatePickerType<T> = UseControllerProps<T> &
  ControlProps &
  Omit<DatePickerProps, "renderInput" | "onChange" | "value">;

function DatePicker<T>({
  name,
  control,
  defaultValue,
  label,
  helperText,
  fullWidth,
  controlProps,
  required,
  ...props
}: DatePickerType<T>) {
  const {
    field: { onChange, value, ref, ...inputProps },
    fieldState: { error: fieldError },
  } = useController({ name, control, defaultValue });

  const handleChange = (newValue: unknown) => {
    if (!isValid(newValue)) return onChange("");
    onChange(formatISODate(newValue as Date, "/"));
  };

  const [open, setOpen] = useState(false);

  return (
    <InputControl
      fieldError={fieldError}
      fullWidth={fullWidth}
      label={label}
      helperText={helperText}
      required={required}
      {...controlProps}
    >
      <Stack direction="row">
        <MuiDatePicker
          {...props}
          {...inputProps}
          inputFormat="yyyy-MM-dd"
          mask="____-__-__"
          value={value ? safeParseISO(value as string) : null}
          onChange={handleChange}
          inputRef={ref}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              error={!!fieldError}
              sx={{ backgroundColor: "#fff" }}
              onClick={() => setOpen(true)}
            />
          )}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
        />

        {value && (
          <IconButton onClick={() => onChange("")} sx={{ ml: "-72px", mr: 6 }}>
            <CloseIcon sx={{ width: 16 }} />
          </IconButton>
        )}
      </Stack>
    </InputControl>
  );
}

export { DatePicker };
