import { Stack, Typography } from "@mui/material";
import React from "react";

const WelcomeChat = () => {
  return (
    <Stack
      bgcolor="#fff"
      height="100%"
      borderRadius="0 8px 8px 0"
      justifyContent={"center"}
      alignItems="center"
    >
      <Typography variant="h4" color={"#D9D9D9"}>
        Choose a chat to start the conversation
      </Typography>
    </Stack>
  );
};

export default WelcomeChat;
