import { Box, Divider, InputAdornment, Stack } from "@mui/material";
import { Input } from "components/Form";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import RoomList from "./RoomList";
import SearchIcon from "@mui/icons-material/Search";
import { MinimizeScrollbar } from "components/MinimizeScrollbar";
import { RoomType } from "lib/types/room";
import { AffiliatorData } from "common/Type/AffiliatorType";

interface Props {
  handleClick: (e: RoomType) => void;
  conversationSelected: number;
  dataRooms: RoomType[];
  handleSetDataRooms: (values: RoomType[]) => void;
}

const ChatSidebar: React.FC<Props> = ({
  handleClick,
  conversationSelected,
  dataRooms,
  handleSetDataRooms,
}) => {
  const { control, handleSubmit, watch } = useForm<AffiliatorData>({
    defaultValues: {
      name: "",
    },
  });

  const onSubmit: SubmitHandler<AffiliatorData> = (values) => {
    const newArr = dataRooms.filter((el) =>
      el.affiliator.name.toLowerCase().includes(values.name)
    );
    handleSetDataRooms(newArr);
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (value.name === "") {
        handleSetDataRooms(dataRooms);
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <Stack sx={{ backgroundColor: "#1F2039" }}>
      <Box
        paddingX={1}
        paddingTop={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          name="name"
          label=""
          control={control}
          fullWidth
          placeholder="Search..."
          sx={{ borderRadius: 8 }}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <Divider
          sx={{
            borderBottom: `1px solid #D9D9D9`,
            marginTop: "15px",
            marginX: "-16px",
          }}
        />
      </Box>
      <MinimizeScrollbar
        sx={{
          height: 700,
          backgroundColor: "#1F2039",
          "& .MuiBox-root": { padding: 0 },
        }}
      >
        <RoomList
          handleClick={handleClick}
          conversationSelected={conversationSelected}
          dataRooms={dataRooms}
        />
      </MinimizeScrollbar>
    </Stack>
  );
};

export default ChatSidebar;
