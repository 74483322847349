import { PERM } from "common/constant";
import { lazy } from "react";
import Login from "../containers/Auth/Login";
import { useTranslation } from "react-i18next";

const Index = lazy(() => import("../containers/Admin/index"));
const ProjectList = lazy(
  () => import("../containers/Admin/Project/List/index")
);
const ProjectForm = lazy(
  () => import("../containers/Admin/Project/Form/ProjectInformation")
);
const ProjectDetail = lazy(
  () => import("../containers/Admin/Project/Form/ProjectDetail")
);
const ProjectOwnerForm = lazy(
  () => import("containers/Admin/ProjectOwner/ProjectOwnerForm")
);
const ProjectOwnerList = lazy(
  () => import("containers/Admin/ProjectOwner/ProjectOwnerList")
);
const AffiliatorList = lazy(
  () => import("containers/Admin/Affiliator/AffiliatorList")
);
const AffiliatorDetail = lazy(
  () => import("containers/Admin/Affiliator/Form/AffiliatorDetail")
);
const Sponsor = lazy(() => import("containers/Admin/Sponsor/Sponsor"));
const SponsorDetail = lazy(
  () => import("containers/Admin/Sponsor/SponsorDialog")
);
const Token = lazy(() => import("containers/Admin/Token/List/index"));
const TokenForm = lazy(() => import("containers/Admin/Token/Form/index"));
const Network = lazy(() => import("containers/Admin/Network/Network"));
const NetworkForm = lazy(() => import("containers/Admin/Network/NetworkForm"));
const ProjectHistory = lazy(
  () => import("containers/Admin/Dashboard/HistoryBuy")
);
const Home = lazy(() => import("containers/Home"));
const WhiteListAffiliatorDetail = lazy(
  () =>
    import("containers/Admin/Project/Form/WhiteList/WhiteListAffiliatorDetail")
);
const AffiliatorGroupList = lazy(
  () => import("containers/Admin/AffiliatorGroup/AffiliatorGroupList")
);
const AffiliatorGroupForm = lazy(
  () => import("containers/Admin/AffiliatorGroup/Form/AffiliatorGroupForm")
);
const GroupSetting = lazy(
  () => import("containers/Admin/AffiliatorGroup/Form/GroupSetting/Setting")
);
const ChangePassword = lazy(() => import("containers/ChangePassword"));

const SNSPromotion = lazy(
  () => import("containers/Admin/SNSPromotion/SNSPromotionList")
);
const ProposalTab = lazy(
  () => import("containers/Admin/SNSPromotion/ProposalTab")
);
const Works = lazy(
  () => import("containers/Admin/SNSPromotion/Works/WorkProposal")
);
const TierSettingForm = lazy(
  () => import("containers/Admin/2Tier/2TierSettingForm")
);
const InfluencerList = lazy(
  () => import("containers/Admin/Influencer/Influencer")
);
const InfluencerRequest = lazy(
  () => import("containers/Admin/Influencer/InfluencerRequest")
);

export const adminPrefix = "";
export const adminApi = "/admin";

export function LoginRoute() {
  return [
    {
      path: "/resetPassword/:token",
      component: Login,
      permissions: "",
    },
  ];
}

export function AdminRoutes() {
  const { t } = useTranslation();

  const adminRoutes = [
    {
      path: "/",
      component: Home,
      protected: true,
      permissions: [PERM.ADMIN],
      name: t("menu.dashboard"),
    },
    {
      path: "/project",
      component: ProjectList,
      protected: true,
      permissions: [PERM.ADMIN, PERM.PROJECT_OWNER],
      name: t("menu.project"),
    },
    {
      path: "/project/create",
      component: ProjectForm,
      protected: true,
      permissions: [PERM.ADMIN, PERM.PROJECT_OWNER],
      name: t("project_screen.project_create"),
    },
    {
      path: "/project/edit/:id",
      component: ProjectDetail,
      protected: true,
      permissions: [PERM.ADMIN, PERM.PROJECT_OWNER],
      name: t("project_screen.project_edit"),
    },
    {
      path: "/project/edit/:id/whitelist-affiliator-detail/:affiliator_id",
      component: WhiteListAffiliatorDetail,
      protected: true,
      permissions: [PERM.ADMIN, PERM.PROJECT_OWNER],
      name: "White list",
    },
    {
      path: "/affiliators",
      component: AffiliatorList,
      protected: true,
      permissions: [PERM.ADMIN],
      name: "Influencer",
    },
    {
      path: "/affiliators/edit/:id",
      component: AffiliatorDetail,
      protected: true,
      permissions: [PERM.ADMIN],
      name: "Affiliators edit",
    },
    {
      path: "/sponsors",
      component: ProjectOwnerList,
      protected: true,
      permissions: [PERM.ADMIN],
      name: "Sponsor",
    },
    {
      path: "/sponsors/create",
      component: ProjectOwnerForm,
      protected: true,
      permissions: [PERM.ADMIN],
      name: "Sponsor create",
    },
    {
      path: "/sponsors/edit/:id",
      component: ProjectOwnerForm,
      protected: true,
      permissions: [PERM.ADMIN],
      name: "Sponsor edit",
    },
    {
      path: "/contacts",
      component: Sponsor,
      protected: true,
      permissions: [PERM.ADMIN],
      name: "Contact list",
    },
    {
      path: "/contacts/detail/:id",
      component: SponsorDetail,
      protected: true,
      permissions: [PERM.ADMIN],
      name: "Contact detail",
    },
    {
      path: "/tokens",
      component: Token,
      protected: true,
      permissions: [PERM.ADMIN],
      name: "Token list",
    },
    {
      path: "/tokens/create",
      component: TokenForm,
      protected: true,
      permissions: [PERM.ADMIN],
      name: "Token create",
    },
    {
      path: "/tokens/edit/:id",
      component: TokenForm,
      protected: true,
      permissions: [PERM.ADMIN],
      name: "Token edit",
    },
    {
      path: "/networks",
      component: Network,
      protected: true,
      permissions: [PERM.ADMIN],
      name: "Network list",
    },
    {
      path: "/networks/create",
      component: NetworkForm,
      protected: true,
      permissions: [PERM.ADMIN],
      name: "Network create",
    },
    {
      path: "/networks/edit/:id",
      component: NetworkForm,
      protected: true,
      permissions: [PERM.ADMIN],
      name: "Network edit",
    },
    {
      path: "/detail/:id",
      component: ProjectHistory,
      protected: true,
      permissions: [PERM.PROJECT_OWNER],
      name: "History transaction",
    },
    {
      path: "/affiliator-groups",
      component: AffiliatorGroupList,
      protected: true,
      permissions: [PERM.ADMIN],
      name: "Influencer groups",
    },
    {
      path: "/affiliator-groups/create",
      component: AffiliatorGroupForm,
      permissions: [PERM.ADMIN],
      name: "Affiliator groups create",
    },
    {
      path: "/affiliator-groups/edit/:id",
      component: AffiliatorGroupForm,
      permissions: [PERM.ADMIN],
      name: "Affiliator groups detail",
    },
    {
      path: "/affiliator-groups/default-setting",
      component: GroupSetting,
      permissions: [PERM.ADMIN],
      name: "Affiliator groups detail",
    },
    {
      path: "/change-password",
      component: ChangePassword,
      permissions: [PERM.PROJECT_OWNER],
      name: "Change password",
    },
    {
      path: "/sns-promotions",
      component: SNSPromotion,
      permissions: [PERM.PROJECT_OWNER],
      name: t("project_screen.sns_promotion"),
    },
    {
      path: "/sns-promotions/edit/:id",
      component: ProposalTab,
      permissions: [PERM.PROJECT_OWNER],
      name: t("sns_promotion_screen.sns_proposal"),
    },
    {
      path: "/sns-promotions/edit/:id/:proposal_id",
      component: Works,
      protected: true,
      permissions: [PERM.PROJECT_OWNER],
      name: t("sns_promotion_screen.work"),
    },
    {
      path: "/2tier-setting",
      component: TierSettingForm,
      permissions: [PERM.ADMIN],
      name: "2Tier Setting",
    },
    {
      path: "/influencers",
      component: InfluencerList,
      permissions: [PERM.PROJECT_OWNER],
      name: t("sns_promotion_screen.influencer"),
    },
    {
      path: "/influencers/edit/:id",
      component: InfluencerRequest,
      permissions: [PERM.PROJECT_OWNER],
      name: t("sns_promotion_screen.request"),
    },
  ];

  return { adminRoutes };
}
