import { Box, Divider, Stack, styled, Typography } from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import UserInfo from "./UserInfo";
import { MessageType, RoomType } from "lib/types";
import { MinimizeScrollbar } from "components/MinimizeScrollbar";
import { usePaginationQuery } from "lib/hooks";
import { format } from "date-fns";
import { SubmitHandler, useForm } from "react-hook-form";
import { Input } from "components/Form";
import IButton from "components/IButton";
import SendIcon from "@mui/icons-material/Send";
import { ChatContext } from "containers/ChatProvider";

interface Props {
  conversationSelected: number;
  dataRooms: RoomType[];
}

const ChatWindow: React.FC<Props> = ({ conversationSelected, dataRooms }) => {
  const { newMsg, clear, connection } = useContext(ChatContext);
  const [perPage, setPerPage] = useState<number>(10);
  const [messages, setMessages] = useState<MessageType[]>([]);

  const {
    paginationData: dataMessage,
    refetch,
    handleChangeParams,
  } = usePaginationQuery<MessageType>(
    `message?affiliator=${conversationSelected}`,
    {
      per_page: perPage,
    }
  );

  const { control, handleSubmit, reset } = useForm<MessageType>({
    defaultValues: {
      id: undefined,
      content: "",
    },
  });

  const onSubmit: SubmitHandler<MessageType> = async (values) => {
    try {
      const _values = {
        ...values,
        content: values.content.split("\n").join("<br />"),
        affiliator_id: conversationSelected,
        from_po_flag: 1,
      };
      reset();
      connection?.send(
        JSON.stringify({
          command: "message",
          data: _values,
        })
      );
      refetch();
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (conversationSelected) {
      setPerPage(10);
    }
  }, [conversationSelected]);

  useEffect(() => {
    if (dataMessage?.data) {
      setMessages(dataMessage.data);
    }
  }, [dataMessage]);

  const btnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (newMsg && messages.length > 0 && connection) {
      let newArr = [...messages];
      if (
        newArr.some(
          (x) => x.id.toString() !== newMsg.id && x.room_id === +newMsg.room_id
        )
      ) {
        newArr.unshift({
          id: +newMsg.id,
          content: newMsg.content,
          created_at: newMsg.created_at,
          from_po_flag: +newMsg.from_po_flag,
          from_afiliator_flag: +newMsg.from_afiliator_flag,
          room_id: +newMsg.room_id,
        });
      }

      setMessages(newArr);
      connection?.send(
        JSON.stringify({
          command: "read",
          data: {
            affiliator_id: +newMsg.affiliator_id,
          },
        })
      );
      clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMsg, messages, connection]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.shiftKey) {
      // event.preventDefault();
      // handleSubmit(onSubmit);
    } else if (event.key === "Enter") {
      event.preventDefault();
      btnRef?.current?.click();
    }
  };

  const handleScroll = useCallback(
    (event: any) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;
      const scroll = scrollHeight + scrollTop - clientHeight;
      if (scroll === 0) {
        const params = {
          per_page: perPage + 10,
        };
        setPerPage((pre) => pre + 10);
        handleChangeParams(params);
      }
    },
    [handleChangeParams, perPage]
  );

  return (
    <Box>
      <Box
        sx={{
          padding: "16px",
        }}
      >
        {dataRooms
          ?.filter((item) => conversationSelected === item.affiliator_id)
          .map((item) => (
            <UserInfo
              key={item.affiliator_id}
              name={item.affiliator.name}
              url={item.affiliator.avatar_url}
            />
          ))}

        <Divider
          sx={{
            borderBottom: `1px solid #D9D9D9`,
            marginTop: "15px",
            marginX: "-16px",
          }}
        />
      </Box>
      <Box
        borderRadius="0 0 8px 0"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack justifyContent="flex-end">
          <Box>
            <MinimizeScrollbar
              onScroll={
                perPage < Number(dataMessage.pageCount)
                  ? handleScroll
                  : undefined
              }
              sx={{
                height: 580,
              }}
              contentProps={{
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              {messages?.length > 0 ? (
                messages.map((el) => (
                  <>
                    {el.from_afiliator_flag === 1 && (
                      <Stack mb={1} key={el.id}>
                        <AffChat
                          sx={{ maxWidth: { xs: 250, sm: 350, md: 460 } }}
                        >
                          {el.content && (
                            <Typography
                              sx={{
                                wordBreak: "break-word",
                                fontSize: 14,
                              }}
                              component="div"
                              dangerouslySetInnerHTML={{
                                __html: el.content.replace(
                                  /<div .*?>/g,
                                  "<div>"
                                ),
                              }}
                            />
                          )}
                        </AffChat>
                        <Typography sx={{ ml: 2, fontSize: 10 }}>
                          {el.created_at &&
                            format(new Date(el.created_at), "yyyy/MM/dd HH:mm")}
                        </Typography>
                      </Stack>
                    )}
                    {el.from_po_flag === 1 && (
                      <Stack key={el.id}>
                        <POChat
                          sx={{ maxWidth: { xs: 250, sm: 350, md: 460 } }}
                        >
                          {el.content && (
                            <Typography
                              sx={{
                                wordBreak: "break-word",
                                fontSize: 14,
                              }}
                              component="div"
                              dangerouslySetInnerHTML={{
                                __html: el.content.replace(
                                  /<div .*?>/g,
                                  "<div>"
                                ),
                              }}
                            />
                          )}
                        </POChat>
                        <Typography
                          sx={{
                            display: "flex",
                            marginLeft: "auto",
                            fontSize: 10,
                          }}
                        >
                          {el.created_at &&
                            format(new Date(el.created_at), "yyyy/MM/dd HH:mm")}
                        </Typography>
                      </Stack>
                    )}
                  </>
                ))
              ) : (
                <Typography align="center" sx={{ marginTop: 2 }}>
                  No message
                </Typography>
              )}
            </MinimizeScrollbar>
          </Box>
          <Box mt={2} />

          <Stack direction="row" spacing={2} marginX={2}>
            <Input
              name="content"
              label=""
              control={control}
              rows={2}
              placeholder="Type your message"
              fullWidth
              multiline
              onKeyDown={handleKeyDown}
            />

            <IButton type="submit" ref={btnRef}>
              <SendIcon sx={{ fill: "#0052cc", cursor: "pointer" }} />
            </IButton>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default ChatWindow;

const POChat = styled("div")(({ theme }) => ({
  backgroundColor: "#0089b5",
  padding: "15px 20px",
  borderRadius: 10,
  display: "flex",
  marginLeft: "auto",
  [theme.breakpoints.down("sm")]: {
    img: {
      width: 200,
    },
  },
}));

const AffChat = styled("div")(({ theme }) => ({
  backgroundColor: "#56CCF2",
  padding: "15px 20px",
  borderRadius: 10,
  display: "flex",
  marginRight: "auto",
  marginLeft: 10,
}));
