import { useEffect, useState } from "react";
import { useEventListener } from "./useEventListener";

function useScreen() {
  const getScreen = () => {
    if (typeof window !== "undefined" && window.screen) {
      return window.screen;
    }
    return undefined;
  };

  const getInnerScreen = () => {
    if (typeof window !== "undefined") {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }
    return undefined;
  };

  const [screen, setScreen] = useState<Screen | undefined>(getScreen());
  const [inner, setInner] = useState<
    { width: number; height: number } | undefined
  >(getInnerScreen());

  function handleSize() {
    setScreen(getScreen());
    setInner(getInnerScreen());
  }

  useEventListener("resize", handleSize);

  // Set size at the first client-side load
  useEffect(() => {
    handleSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { screen, innerScreen: inner };
}

export { useScreen };
