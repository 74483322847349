/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import MetamaskLogo from "assets/image/metamask.png";
import WalletConnectLogo from "assets/image/walletconnect.png";
import { DialogBase } from "components/Dialog";
import { Connector, useConnect, useAccount, useDisconnect } from "wagmi";
import React from "react";
import { styled } from "@mui/system";

type PropType = {
  open: boolean;
  onClose: () => void;
};

const ModalConnect: React.FC<PropType> = ({ open, onClose }) => {
  const { connect, connectors } = useConnect();
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();

  const handleCancel = () => {
    onClose();
  };

  const handleConnect = (connector: Connector<any, any>) => {
    connect({ connector });
    onClose();
  };

  const handleDisconnect = () => {
    disconnect();
    onClose();
  };

  return (
    <DialogBase
      open={open}
      onClose={handleCancel}
      title={isConnected ? "Welcome to Bitbull" : "Connect to your wallet"}
    >
      <Box>
        <DialogContent sx={{ px: 0 }}>
          {isConnected ? (
            <WalletInfo address={address} handleDisconnect={handleDisconnect} />
          ) : (
            <ConnectorList
              connectors={connectors}
              handleConnect={handleConnect}
            />
          )}
        </DialogContent>
      </Box>
    </DialogBase>
  );
};

const WalletInfo = ({
  address,
  handleDisconnect,
}: {
  address: string | undefined;
  handleDisconnect: () => void;
}) => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Stack spacing={2} alignItems="center">
        <Typography variant="h5" sx={{ color: "text.secondary" }}>
          {address}
        </Typography>
        <Button
          variant="contained"
          sx={{ width: "250px" }}
          onClick={() => handleDisconnect()}
        >
          Disconnect
        </Button>
      </Stack>
    </Box>
  );
};

const ConnectorList = ({
  connectors,
  handleConnect,
}: {
  connectors: Connector<any, any>[];
  handleConnect: (connector: Connector<any, any>) => void;
}) => {
  return (
    <Grid container spacing={2}>
      {connectors.map((connector, idx) => (
        <StyledGrid
          onClick={() => handleConnect(connector)}
          key={idx}
          display={"flex"}
          justifyContent={"center"}
          item
          xs={12 / Number(connectors.length)}
        >
          <Stack alignItems={"center"}>
            <img
              src={
                connector.id === "metaMask" ? MetamaskLogo : WalletConnectLogo
              }
              alt={connector.name}
              width={100}
              height={100}
            />
            <Typography
              sx={{
                mt: 1,
              }}
            >
              {connector.name}
            </Typography>
          </Stack>
        </StyledGrid>
      ))}
    </Grid>
  );
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
  cursor: "pointer",
}));

export { ModalConnect };
