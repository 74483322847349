import React, { useCallback } from "react";
import { Alert, Box, styled } from "@mui/material";
import { Chain, useNetwork, useSwitchNetwork } from "wagmi";
// import { ACTIVE_CHAIN_ID, ChainType } from "configs/blockchain/acceptedChains";
import { ethers } from "ethers";
import { BinanceChain } from "configs/blockchain/chainSettings";
import { useTranslation } from "react-i18next";

type Props = {
  networkId?: number;
};

const WrongNetworkAlert: React.FC<Props> = ({ networkId }) => {
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork({
    onError: (error) => {
      handleAddNetwork(BinanceChain());
    },
  });

  const handleSwitchNetwork = () => {
    switchNetwork?.(networkId);
  };
  const {t} = useTranslation()

  const handleAddNetwork = useCallback(async (value: Chain) => {
    if (window.ethereum) {
      await window.ethereum.request({
        // @ts-ignore
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: ethers.utils.hexStripZeros(
              ethers.BigNumber.from(value.id)._hex
            ),
            // @ts-ignore
            rpcUrls: value.rpcUrls.default,
            chainName: value.name,
            blockExplorerUrls: value.blockExplorers,
            nativeCurrency: value.nativeCurrency,
          },
        ],
      });
    }
    return;
  }, []);

  return chain?.unsupported || chain?.id !== networkId ? (
    <Box mb={2}>
      <Alert severity="warning">
        {t('sns_promotion_screen.unsupported_network')}{" "}
        <ClickHereText onClick={() => handleSwitchNetwork()}>
          {t('sns_promotion_screen.click_here')}
        </ClickHereText>{" "}
        {t('sns_promotion_screen.change_network')}
      </Alert>
    </Box>
  ) : null;
};

const ClickHereText = styled("span")(({ theme }) => ({
  fontWeight: 700,
  cursor: "pointer",
  textDecoration: "underline",
}));
export { WrongNetworkAlert };
