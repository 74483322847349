import { Box, Grid } from "@mui/material";
import { DialogBase } from "components/Dialog";
import React, { useContext, useEffect, useState } from "react";
import WelcomeChat from "./components/WelcomeChat";
import ClearIcon from "@mui/icons-material/Clear";
import { usePaginationQuery } from "lib/hooks";
import { AffiliatorData } from "common/Type/AffiliatorType";
import { ChatContext } from "containers/ChatProvider";
import SidebarAdmin from "./components/SideBarAdmin";
import MessageAdmin from "./components/MessageAdmin";

type ChatProps = {
  open: boolean;
  onClose(): void;
  affID?: number;
};

const ChatAdmin = ({ open, onClose, affID }: ChatProps) => {
  const { newMsg, clear, connection, unReadMsg, updateUnRead } = useContext(
    ChatContext
  );
  const [conversationSelected, setConversationSelected] = useState<number>(0);

  useEffect(() => {
    if (affID) {
      setConversationSelected(affID);
    }
  }, [affID]);

  const handleClick = (item: AffiliatorData) => {
    setConversationSelected(item.id);
    connection?.send(
      JSON.stringify({
        command: "read",
        data: {
          affiliator_id: item.id,
        },
      })
    );
    let newArr = [...unReadMsg];
    newArr = newArr?.filter((x) => x.affiliator_id !== item.id);

    updateUnRead(newArr);
  };

  const { paginationData: dataAff } = usePaginationQuery<AffiliatorData>(
    "affiliators",
    { page: 1, per_page: 9999 }
  );

  const [dataAffiliator, setAffiliator] = useState<AffiliatorData[]>([]);

  useEffect(() => {
    if (dataAff?.data) {
      setAffiliator(dataAff.data);
    }
  }, [dataAff]);

  useEffect(() => {
    if (newMsg && dataAffiliator?.length > 0 && open) {
      let newArr = [...dataAffiliator];
      if (newMsg?.rooms?.length > 0) {
        const rooms = [...newMsg.rooms];

        const sameData = dataAffiliator?.filter((x) => {
          return rooms.find((room: any) => room.affiliator_id === x.id);
        });
        const formatSameData = sameData.map((x) => ({
          ...x,
          has_unread_msg: true,
        }));

        const otherData = dataAffiliator?.filter((x) => {
          if (rooms.some((room: any) => room.affiliator_id === x.id)) {
            return null;
          } else {
            return x;
          }
        });
        const formatOtherData = otherData.filter((x) => x);

        newArr = [...formatSameData, ...formatOtherData] as AffiliatorData[];
        newArr = [...new Set(newArr)];

        setAffiliator(newArr);
      } else {
        const index = dataAffiliator.findIndex(
          (x: AffiliatorData) => x.id === +newMsg.affiliator_id
        );

        if (index !== -1) {
          let removeData = newArr.splice(index, 1).map((el) => {
            if (el.id !== conversationSelected) {
              return { ...el, has_unread_msg: true };
            } else {
              return el;
            }
          });

          updateUnRead(removeData);

          newArr.unshift(removeData[0]);

          setAffiliator(newArr);
        }
      }
      clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMsg, open, conversationSelected]);

  useEffect(() => {
    if (conversationSelected && dataAffiliator.length > 0) {
      let newArr = [...dataAffiliator];

      newArr = newArr.map((el) => {
        if (el.id === conversationSelected) {
          return { ...el, has_unread_msg: false };
        } else {
          return el;
        }
      });
      setAffiliator(newArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationSelected]);

  return (
    <DialogBase open={open} sx={{ "& .MuiDialogContent-root": { padding: 0 } }}>
      <ClearIcon
        onClick={onClose}
        sx={{ cursor: "pointer", position: "absolute", top: 20, right: 20 }}
      />
      <Box>
        <Grid container>
          <Grid item xs={3}>
            <SidebarAdmin
              handleClick={handleClick}
              conversationSelected={conversationSelected}
              dataAff={dataAffiliator}
              handleSetDataRooms={(values) => setAffiliator(values)}
            />
          </Grid>
          <Grid item xs={9}>
            {!conversationSelected && !affID ? (
              <WelcomeChat />
            ) : (
              <MessageAdmin
                conversationSelected={conversationSelected}
                dataAff={dataAffiliator}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </DialogBase>
  );
};

export default ChatAdmin;
