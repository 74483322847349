import {
  Switch as MuiSwitch,
  FormControlLabel,
  FormControlProps,
} from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";
import { AddControlProps, InputControl } from "..";
import React from "react";
import { FormHelper } from "..";

export type SwitchProps<T> = UseControllerProps<T> &
  AddControlProps & {
    controlProps?: FormControlProps;
    disabled?: boolean;
    helperText?: string;
  };

function Switch<T>({
  name,
  label,
  control,
  controlProps,
  disabled = false,
  helperText,
  required,
}: SwitchProps<T>) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <InputControl
      fieldError={error}
      helperText={helperText}
      required={required}
      {...controlProps}
    >
      <FormControlLabel
        control={<MuiSwitch onChange={handleChange} checked={!!value} />}
        label={label as string}
        labelPlacement="end"
        sx={{
          "& .MuiTypography-root": { fontSize: 15, fontWeight: 700 },
        }}
        disabled={disabled}
      />
      {/* {!!error && (
        <FormHelper error>
          {typeof error === "boolean" ? helperText : error?.message}
        </FormHelper>
      )} */}
    </InputControl>
  );
}

export { Switch };
