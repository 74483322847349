import React from "react";
import "./App.css";
import AuthProvider from "./containers/AuthProvider";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "react-toastify/dist/ReactToastify.css";
import MainRouter from "./routes/MainRouter";
import "./scrollbar.css";
import { QueryClientProvider } from "react-query";
import { queryClient } from "lib/utils";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DialogUtilsProvider from "lib/providers/DialogProvider";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { CHAIN_SETTINGS } from "configs/blockchain/chainSettings";
import ChatProvider from "containers/ChatProvider";
import RateProvider from "containers/TierSettingProvider";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0052cc",
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: 700,
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        },
      },
    },
  },
});

const {
  provider,
  webSocketProvider,
  chains,
} = configureChains(CHAIN_SETTINGS(), [publicProvider()]);

const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
  connectors: [
    new MetaMaskConnector({
      chains,
      options: { shimChainChangedDisconnect: false },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
  ],
});

function App() {
  return (
    <AuthProvider>
      <ChatProvider>
        <RateProvider>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CssBaseline />
                <DialogUtilsProvider>
                  <WagmiConfig client={client}>
                    <MainRouter />
                  </WagmiConfig>
                </DialogUtilsProvider>
              </LocalizationProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </RateProvider>
      </ChatProvider>
    </AuthProvider>
  );
}

export default App;
