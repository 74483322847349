import React, { useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { AuthContext } from "../AuthProvider";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../assets/svg/bull_logo.svg";
import color from "../../common/color.json";
import { IconButton, Popover } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {
  Box,
  Divider,
  Link,
  Stack,
  SwipeableDrawer,
  Typography,
  useTheme,
} from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useAccount } from "wagmi";
import { ModalConnect } from "./walletconnect";
import { useMatchesSize, useModalState } from "lib/hooks";
import { formatAddress } from "lib/utils";
import { styled } from "@mui/system";
import Chat from "containers/Admin/Chat/Chat";
import { ChatContext } from "containers/ChatProvider";
import ChatAdmin from "containers/Admin/Chat/ChatAdmin";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useQuery } from "react-query";
import { ListNotify } from "./notification";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import IButton from "components/IButton";
import CloseIcon from "@mui/icons-material/Close";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import InterestsIcon from "@mui/icons-material/Interests";
import EmailIcon from "@mui/icons-material/Email";
import TwitterIcon from "@mui/icons-material/Twitter";
import LanguageIcon from "@mui/icons-material/Language";
import TelegramIcon from "@mui/icons-material/Telegram";
import MouseIcon from "@mui/icons-material/Mouse";

const drawerWidth = 260;
const AddressShowNumber = 5;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: color.primary,
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftBar: {
    display: "flex",
    alignItems: "center",
  },
  breadcrumbs: {
    paddingLeft: 30,
  },
  menuButton: {
    marginRight: 36,
  },
  icon: {
    justifyContent: "flex-end",
  },
  rightBar: {
    display: "flex",
    "& svg": {
      fontSize: "2rem",
      width: "2rem",
      height: "2rem",
    },
  },
  showHeader: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  active: {
    borderLeft: "#1fb1e7 solid 2px",
    color: "#1fb1e7",
  },
  unActive: {
    color: "#fff",
  },
}));

export default function Header(props: any) {
  const classes = useStyles();
  const { admin, clear }: any = useContext(AuthContext);
  const { isConnected, address } = useAccount();

  const {
    isOpen: isOpenModalConnect,
    onClose: onCloseModalConnect,
    onOpen: onOpenModalConnect,
  } = useModalState();

  const {
    isOpen: isOpenMenuMobile,
    onClose: onCloseMenuMobile,
    onOpen: onOpenMenuMobile,
  } = useModalState();

  const history = useHistory();
  const logout = () => {
    if (admin) {
      localStorage.removeItem("lang");
      clear();
      history.push("/login");
    }
  };
  const [show, setShow] = useState(true);
  useEffect(() => {
    if (window.screen.width > 1000) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [
    anchorElNotify,
    setAnchorElNotify,
  ] = React.useState<HTMLElement | null>(null);
  const [
    anchorElLocale,
    setAnchorElLocale,
  ] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const theme = useTheme();

  const {
    isOpen: isOpenChat,
    onClose: onCloseChat,
    onOpen: onOpenChat,
  } = useModalState();

  const handleChat = () => {
    onOpenChat();
  };

  const { unReadMsg, newMsg } = useContext(ChatContext);

  const [numberNotify, setNumberNotify] = useState<any>(null);
  const handlePopoverOpenNotify = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotify(event.currentTarget);
  };

  const handlePopoverCloseNotify = () => {
    setAnchorElNotify(null);
    setNumberNotify(0);
  };

  const openNotify = Boolean(anchorElNotify);

  const { data, refetch } = useQuery<number>([`new-notification`]);

  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState<string>(
    i18n.language ? i18n.language : "en"
  );

  const flag = useMemo(() => {
    if (language === "en") return "EN";
    if (language === "jp") return "JP";
    if (language === "ko") return "KO";
  }, [language]);

  const welcome = useMemo(() => {
    if (i18n.language === "en") {
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography fontWeight={700}>Welcome:</Typography>

          <Typography sx={{ color: "#1fb1e7", fontWeight: 700 }}>
            {admin?.project_owner?.name}
          </Typography>
        </Stack>
      );
    }
    if (i18n.language === "jp") {
      return (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography fontWeight={700}>ようこそ</Typography>

          <Typography sx={{ color: "#1fb1e7", fontWeight: 700 }}>
            {admin?.project_owner?.name}
          </Typography>
        </Stack>
      );
    }
    if (i18n.language === "ko") {
      return (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography sx={{ color: "#1fb1e7", fontWeight: 700 }}>
            {admin?.project_owner?.name}
          </Typography>

          <Typography fontWeight={700}>님</Typography>

          <Typography fontWeight={700}>환영합니다</Typography>
        </Stack>
      );
    }
  }, [admin, i18n.language]);

  const handlePopoverOpenLocale = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLocale(event.currentTarget);
  };

  const handlePopoverCloseLocale = () => {
    setAnchorElLocale(null);
  };

  const openLocale = Boolean(anchorElLocale);

  const changeLocation = (value: string) => {
    i18n.changeLanguage(value);
    localStorage.setItem("lang", value);
    setLanguage(value);
    handlePopoverCloseLocale();
  };

  const { xs } = useMatchesSize();
  const location = useLocation();
  const active = (path: string) => {
    if (path) {
      const pathname = location.pathname.split("/");
      const p = path.split("/");
      if (pathname[1] === p[1]) {
        return true;
      }
    }
    return false;
  };

  const handleLink = (path: string) => {
    history.push(path);
    onCloseMenuMobile();
  };

  const handleUse = () => {
    if (i18n.language == "en" || i18n.language == "ko") {
      return "https://bitbull-manual.gitbook.io/bitbull-project-owner/project-owner-en/project-creation-social-media-wl";
    }
    if (i18n.language == "jp") {
      return "https://bitbull-manual.gitbook.io/bitbull-project-owner/project-owner-jp/purojekuto-snswl";
    }
    return "";
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: props.open,
        })}
      >
        <Toolbar
          className={classes.toolbar}
          style={{
            height: "80px",
            backgroundColor: "#1C1C33",
            backgroundImage: `url("/images/bg.png")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            padding: "0px",
          }}
        >
          {xs ? (
            <>
              {admin?.project_owner_id && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  marginX={1}
                  width="100%"
                >
                  <IButton onClick={onOpenMenuMobile}>
                    <MenuIcon sx={{ fill: "#9d9d9d" }} />
                  </IButton>

                  <Stack direction="row" alignItems="center" spacing={1}>
                    <img style={{ height: 30 }} src={logo} alt="" />
                    <img
                      src="/images/logo-text.svg"
                      alt="logo"
                      width={100}
                      height={30}
                    />
                  </Stack>

                  <Stack direction="row" spacing={0.5}>
                    <IconButton onClick={(e) => handlePopoverOpenNotify(e)}>
                      <NotificationsIcon sx={{ fill: "#fff" }} />
                      <Box
                        sx={{
                          border: "1px solid red",
                          borderRadius: "50%",
                          backgroundColor: "red",
                          minWidth: 20,
                          height: 20,
                          display: "flex",
                          marginLeft: "-8px",
                          marginTop: "-20px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {numberNotify === 0 ? (
                          <Typography
                            sx={{
                              fontSize: 12,
                              color: "#fff",
                            }}
                          >
                            0
                          </Typography>
                        ) : (
                          <>
                            {data != undefined && (
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  color: "#fff",
                                }}
                              >
                                {data}
                              </Typography>
                            )}
                          </>
                        )}
                      </Box>
                    </IconButton>

                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        handlePopoverOpenLocale(e);
                      }}
                    >
                      {flag}
                      <ArrowDropDownIcon />
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </>
          ) : (
            <>
              <div className={classes.leftBar} style={{ paddingLeft: 15 }}>
                {show === true && (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <img style={{ height: 70 }} src={logo} alt="" />
                    <img
                      src="/images/logo-text.svg"
                      alt="logo"
                      width={240}
                      height={50}
                    />
                  </Stack>
                )}
              </div>
              <Stack direction="row" spacing={2}>
                {admin?.project_owner_id && welcome}

                <StyleStack
                  direction="row"
                  alignItems="center"
                  onClick={() => onOpenModalConnect()}
                >
                  <IconButton color="inherit">
                    <AccountBalanceWalletIcon />
                  </IconButton>

                  <Typography>
                    {isConnected &&
                      formatAddress(address as string, AddressShowNumber)}
                  </Typography>
                </StyleStack>

                {/* <Tooltip title="Chat">
              <IconButton onClick={handleChat}>
                <ChatIcon sx={{ fill: "#fff" }} />

                {(Number(unReadMsg?.length) > 0 || newMsg !== null) && (
                  <FiberManualRecordIcon
                    sx={{
                      width: 10,
                      height: 10,
                      fill: "red",
                      display: "flex",
                      marginLeft: "-8px",
                      marginTop: "-20px",
                    }}
                  />
                )}
              </IconButton>
            </Tooltip> */}

                {admin?.project_owner_id && (
                  <>
                    <IconButton onClick={(e) => handlePopoverOpenNotify(e)}>
                      <NotificationsIcon sx={{ fill: "#fff" }} />
                      <Box
                        sx={{
                          border: "1px solid red",
                          borderRadius: "50%",
                          backgroundColor: "red",
                          minWidth: 20,
                          height: 20,
                          display: "flex",
                          marginLeft: "-8px",
                          marginTop: "-20px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {numberNotify === 0 ? (
                          <Typography
                            sx={{
                              fontSize: 12,
                              color: "#fff",
                            }}
                          >
                            0
                          </Typography>
                        ) : (
                          <>
                            {data != undefined && (
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  color: "#fff",
                                }}
                              >
                                {data}
                              </Typography>
                            )}
                          </>
                        )}
                      </Box>
                    </IconButton>

                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        handlePopoverOpenLocale(e);
                      }}
                    >
                      {flag}
                      <ArrowDropDownIcon />
                    </Stack>
                  </>
                )}

                <Box
                  style={{
                    borderRadius: theme.spacing(1),
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: theme.spacing(1, 2),
                    height: theme.spacing(6),
                    border: "1px solid",
                    marginRight: 30,
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    onClick={(e) => handlePopoverOpen(e)}
                    sx={{ cursor: "pointer" }}
                  >
                    <IconButton color="inherit">
                      <AccountCircleIcon />
                    </IconButton>

                    <Typography>{admin.email}</Typography>
                  </Stack>
                </Box>
              </Stack>
            </>
          )}

          <Popover
            id="mouse-over-popover"
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Stack>
              {!!admin?.project_owner_id && (
                <Typography
                  style={{ padding: 8, cursor: "pointer" }}
                  onClick={() => history.push("/change-password")}
                >
                  Change password
                </Typography>
              )}

              {!!admin?.project_owner_id && <Divider />}

              <Typography
                style={{ padding: 8, cursor: "pointer" }}
                onClick={() => logout()}
              >
                Logout
              </Typography>
            </Stack>
          </Popover>

          <Popover
            id="mouse-over-popover"
            open={openNotify}
            anchorEl={anchorElNotify}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handlePopoverCloseNotify}
            disableRestoreFocus
          >
            <ListNotify
              handleClose={(values) => setAnchorElNotify(values)}
              refetch={refetch}
            />
          </Popover>

          <Popover
            open={openLocale}
            anchorEl={anchorElLocale}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                padding: "10px",
                width: "150px",
              }}
              spacing={2}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ width: "100%", cursor: "pointer" }}
                onClick={() => changeLocation("en")}
              >
                English
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ width: "100%", cursor: "pointer" }}
                onClick={() => changeLocation("jp")}
              >
                Japanese
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ width: "100%", cursor: "pointer" }}
                onClick={() => changeLocation("ko")}
              >
                Korean
              </Stack>
            </Stack>
          </Popover>
        </Toolbar>
        <ModalConnect open={isOpenModalConnect} onClose={onCloseModalConnect} />

        <SwipeableDrawer
          anchor="left"
          open={isOpenMenuMobile}
          onClose={onCloseMenuMobile}
          onOpen={onOpenMenuMobile}
          PaperProps={{
            sx: {
              background: "#21243fcc",
              width: drawerWidth,
            },
          }}
        >
          <Stack
            spacing={4}
            mt={1.5}
            justifyContent="flex-start"
            marginX={2}
            color="white"
          >
            <IButton
              onClick={onCloseMenuMobile}
              sx={{ marginLeft: "auto", marginRight: 1 }}
            >
              <CloseIcon sx={{ fill: "white" }} />
            </IButton>

            {welcome}

            <Box
              style={{
                borderRadius: theme.spacing(1),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: theme.spacing(1, 2),
                height: theme.spacing(5),
                border: "1px solid",
                color: "white",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                onClick={(e) => handlePopoverOpen(e)}
              >
                <IconButton color="inherit">
                  <AccountCircleIcon />
                </IconButton>

                <Typography>{admin.email}</Typography>
              </Stack>
            </Box>

            <Stack
              direction="row"
              alignItems="center"
              onClick={() => onOpenModalConnect()}
              color="white"
              spacing={1}
            >
              <AccountBalanceWalletIcon />

              <Typography>
                {isConnected &&
                  formatAddress(address as string, AddressShowNumber)}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              onClick={() => handleLink("/project")}
              className={active("/project") ? classes.active : classes.unActive}
            >
              <InterestsIcon />

              <Typography>{t("menu.project")}</Typography>
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              onClick={() => handleLink("/sns-promotions")}
              className={
                active("/sns-promotions") ? classes.active : classes.unActive
              }
            >
              <AutoAwesomeMosaicIcon />

              <Typography>{t("menu.sns_promotion")}</Typography>
            </Stack>

            {admin.project_owner?.view_influencer === 1 && (
              <Stack
                direction="row"
                spacing={2}
                onClick={() => handleLink("/influencers")}
                className={
                  active("/influencers") ? classes.active : classes.unActive
                }
              >
                <GroupsOutlinedIcon />

                <Typography>{t("menu.influencer")}</Typography>
              </Stack>
            )}

            <Link
              href={handleUse()}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Stack direction="row" spacing={2}>
                <MouseIcon sx={{ fill: "#fff" }} />

                <Typography color="#fff" fontSize={16}>
                  {t("menu.how_to_use")}
                </Typography>
              </Stack>
            </Link>
          </Stack>

          <Stack
            spacing={1}
            color="white"
            sx={{ display: "flex", mt: "auto", mb: 3, ml: 2 }}
          >
            <Stack direction="row" alignItems="start">
              <EmailIcon
                sx={{
                  fill: "white",
                }}
                viewBox="0 0 28 28"
              />

              <Link
                href="mailto:support@bitbull.online"
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: 14,
                }}
                target="_blank"
              >
                {" "}
                support@bitbull.online
              </Link>
            </Stack>

            <Stack direction="row" alignItems="start">
              <TwitterIcon
                sx={{
                  fill: "white",
                }}
                viewBox="0 0 28 28"
              />

              <Link
                href="https://twitter.com/BitBull_Global"
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: 14,
                }}
                target="_blank"
              >
                https://twitter.com/BitBull_Global
              </Link>
            </Stack>

            <Stack direction="row" alignItems="start">
              <TelegramIcon
                sx={{
                  fill: "white",
                }}
                viewBox="0 0 28 28"
              />

              <Link
                href="https://t.me/bitbullsupport"
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: 14,
                }}
                target="_blank"
              >
                https://t.me/bitbullsupport
              </Link>
            </Stack>

            <Stack direction="row" alignItems="start">
              <LanguageIcon
                sx={{
                  fill: "white",
                }}
                viewBox="0 0 28 28"
              />

              <Link
                href="https://bitbull.online"
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: 14,
                }}
                target="_blank"
              >
                https://bitbull.online
              </Link>
            </Stack>
          </Stack>

          <Stack alignItems="center" mt={2} mb={1}>
            <Typography color="white" fontSize={14}>
              ©2023 All rights reserved
            </Typography>

            <Link
              href="https://bitbull.online"
              style={{ color: "#1fb1e7", fontSize: 14 }}
              target="_blank"
            >
              https://bitbull.online
            </Link>
          </Stack>
        </SwipeableDrawer>

        {admin?.project_owner_id ? (
          <Chat open={isOpenChat} onClose={onCloseChat} />
        ) : (
          <ChatAdmin open={isOpenChat} onClose={onCloseChat} />
        )}
      </AppBar>
    </div>
  );
}
const StyleStack = styled(Stack)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    opacity: 0.75,
  },
}));
