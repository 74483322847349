import DateTimePicker, { DateTimePickerProps } from "@mui/lab/DateTimePicker";
import { FormControlProps, TextField } from "@mui/material";
import { isValid } from "date-fns";
import { formatDateTime, safeParseISO } from "lib/utils";
import { useController, UseControllerProps } from "react-hook-form";
import { AddControlProps, InputControl } from "..";
import React from "react";

type DateTimeProps<T> = UseControllerProps<T> &
  AddControlProps & {
    controlProps?: FormControlProps;
    fullWidth?: boolean;
  } & Omit<DateTimePickerProps, "renderInput" | "onChange" | "value">;

function DateTimePickers<T>({
  name,
  label,
  control,
  controlProps,
  helperText,
  required,
  fullWidth,
  ...props
}: DateTimeProps<T>) {
  const {
    field: { onChange, value, ref, ...inputProps },
    fieldState: { error: fieldError },
  } = useController({ name, control });

  const handleChange = (newValue: unknown) => {
    if (!isValid(newValue)) return;
    onChange(formatDateTime(newValue as Date));
  };

  return (
    <InputControl
      fieldError={fieldError}
      helperText={helperText}
      label={label}
      required={required}
      fullWidth={fullWidth}
      {...controlProps}
    >
      <DateTimePicker
        {...props}
        {...inputProps}
        value={value ? safeParseISO(value as string) : null}
        onChange={handleChange}
        inputRef={ref}
        inputFormat="yyyy-MM-dd HH:mm"
        mask="____-__-__ __:__"
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            error={!!fieldError}
            sx={{ backgroundColor: "#fff" }}
          />
        )}
      />
    </InputControl>
  );
}

export { DateTimePickers };
