import { Box, Grid } from "@mui/material";
import { DialogBase } from "components/Dialog";
import React, { useContext, useEffect, useState } from "react";
import ChatSidebar from "./components/ChatSidebar";
import ChatWindow from "./components/ChatWindow";
import WelcomeChat from "./components/WelcomeChat";
import ClearIcon from "@mui/icons-material/Clear";
import { RoomType } from "lib/types";
import { useQuery } from "react-query";
import { ChatContext } from "containers/ChatProvider";

type ChatProps = {
  open: boolean;
  onClose(): void;
};

const Chat = ({ open, onClose }: ChatProps) => {
  const { newMsg, clear, connection, unReadMsg, updateUnRead } = useContext(
    ChatContext
  );
  const [conversationSelected, setConversationSelected] = useState<number>(0);

  const handleClick = (item: RoomType) => {
    setConversationSelected(item.affiliator_id);
    connection?.send(
      JSON.stringify({
        command: "read",
        data: {
          affiliator_id: item.affiliator_id,
        },
      })
    );
    let newArr = [...unReadMsg];
    newArr = newArr?.filter((x) => x.affiliator_id !== item.affiliator_id);

    updateUnRead(newArr);
  };

  const { data: dataRooms } = useQuery<RoomType[]>(["rooms"]);

  const [rooms, setRooms] = useState<RoomType[]>([]);

  useEffect(() => {
    if (dataRooms) {
      setRooms(dataRooms);
    }
  }, [dataRooms]);

  useEffect(() => {
    if (newMsg && rooms?.length > 0 && open) {
      let newArr = [...rooms];
      if (newMsg?.rooms?.length > 0) {
        const dataRooms = [...newMsg?.rooms];

        const sameData = rooms?.filter((x) => {
          return dataRooms.find(
            (room: any) => room.affiliator_id === x.affiliator_id
          );
        });
        const formatSameData = sameData.map((x) => ({
          ...x,
          has_unread_msg: true,
        }));

        const otherData = rooms?.filter((x) => {
          if (
            dataRooms.some(
              (room: any) => room.affiliator_id === x.affiliator_id
            )
          ) {
            return null;
          } else {
            return x;
          }
        });
        const formatOtherData = otherData.filter((x) => x);

        newArr = [...formatSameData, ...formatOtherData] as RoomType[];
        newArr = [...new Set(newArr)];

        setRooms(newArr);
      } else {
        const index = rooms.findIndex(
          (x: RoomType) => x.id === +newMsg.room_id
        );

        if (index !== -1) {
          let removeData = newArr.splice(index, 1).map((el) => {
            if (el.affiliator_id !== conversationSelected) {
              return { ...el, has_unread_msg: true };
            } else {
              return el;
            }
          });

          updateUnRead(removeData);

          newArr.unshift(removeData[0]);

          setRooms(newArr);
        }
      }
      clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMsg, open, conversationSelected]);

  useEffect(() => {
    if (conversationSelected && rooms.length > 0) {
      let newArr = [...rooms];

      newArr = newArr.map((el) => {
        if (el.affiliator_id === conversationSelected) {
          return { ...el, has_unread_msg: false };
        } else {
          return el;
        }
      });
      setRooms(newArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationSelected]);

  return (
    <DialogBase open={open} sx={{ "& .MuiDialogContent-root": { padding: 0 } }}>
      <ClearIcon
        onClick={onClose}
        sx={{ cursor: "pointer", position: "absolute", top: 20, right: 20 }}
      />
      <Box>
        <Grid container>
          <Grid item xs={3}>
            <ChatSidebar
              handleClick={handleClick}
              conversationSelected={conversationSelected}
              dataRooms={rooms}
              handleSetDataRooms={(values) => setRooms(values)}
            />
          </Grid>
          <Grid item xs={9}>
            {!conversationSelected ? (
              <WelcomeChat />
            ) : (
              <ChatWindow
                conversationSelected={conversationSelected}
                dataRooms={rooms}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </DialogBase>
  );
};

export default Chat;
