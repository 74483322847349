import React, { useContext, Suspense, useEffect } from "react";
import { adminPrefix } from "./AdminRoutes";
import { AuthContext } from "../containers/AuthProvider";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "../containers/Layout/Layout";
import AppLoading from "../components/Loading";
import Login from "../containers/Auth/Login";
import { ChatContext } from "containers/ChatProvider";

export default function MainRouter() {
  const { admin } = useContext(AuthContext);
  const { update } = useContext(ChatContext);

  useEffect(() => {
    const channel = new BroadcastChannel("notifications");
    channel.addEventListener("message", (event) => {
      if (event.data?.data) {
        update(event.data.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <Suspense fallback={<AppLoading />}>
        <Switch>
          <Route path={"/login"} exact strict component={Login} />
          <Route path={adminPrefix} component={admin ? Layout : Login} />
        </Switch>
      </Suspense>
    </Router>
  );
}
