import { msgStoreType } from "common/Type/newMsgType";
import React, { useState, createContext, useEffect } from "react";

const defaultValue: msgStoreType = {
  unReadMsg: null,
  newMsg: null,
  connection: null,
  update: () => {},
  clear: () => {},
  updateUnRead: () => {},
};

export const ChatContext = createContext<msgStoreType>(defaultValue);

const ChatProvider = React.memo((props) => {
  const [newMsg, setNewMsg] = useState<any>(null);
  const [unReadMsg, setUnreadMsg] = useState<any>(null);
  const [status, setStatus] = useState(true);
  const [connection, setConnection] = useState<WebSocket | null>(null);

  useEffect(() => {
    function changeStatus() {
      setStatus(navigator.onLine);
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);

  const connect = () => {
    const connection = new WebSocket(
      (process.env.REACT_APP_WEB_SOCKET as string) +
        localStorage.getItem("user-token")
    );
    connection.onopen = () => {
      connection.send(
        JSON.stringify({
          command: "register",
        })
      );
      connection.send(
        JSON.stringify({
          command: "unread",
        })
      );
    };

    connection.onmessage = (e) => {
      if (JSON.parse(e.data).command === "message") {
        update(JSON.parse(e.data).data);
      }
      if (JSON.parse(e.data).command === "unread") {
        const dataMsg = JSON.parse(e.data).data;
        if (dataMsg?.rooms?.length > 0) {
          update(JSON.parse(e.data).data);
        }

        updateUnRead(JSON.parse(e.data)?.data?.rooms);
      }
    };

    connection.onclose = (e) => {
      setTimeout(function () {
        connect();
      }, 1000);
    };
    setConnection(connection);
  };

  useEffect(() => {
    connect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const update = (data: any) => {
    setNewMsg(data);
  };
  const updateUnRead = (data: any) => {
    setUnreadMsg(data);
  };

  const clear = () => {
    setNewMsg(null);
  };

  const value: msgStoreType = {
    newMsg,
    update,
    clear,
    connection,
    unReadMsg,
    updateUnRead,
  };

  return <ChatContext.Provider value={value as msgStoreType} {...props} />;
});

export default ChatProvider;
